*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  color: var(--font-color-primary);
}
@font-face {
  font-family: nueueBold;
  src: url("./asset/fonts/NeueHaasDisplayBold.ttf");
}
@font-face {
  font-family: nueuelight;
  src: url("./asset/fonts/NeueHaasDisplayLight.ttf");
}
:root{
  --body-bg-primary:#111827;
  --accent-primary:#1f2937;
  --accent-primary-alpha:#1f293798;
  --font-color-primary:#f3f4f6;
  --font-color-secondary:#939ba7;
  --font-color-secondary-alpha:#939ba773;
  --accent-secondary:#8470ff;
  --accent-secondary-alpha:#8370ff67;
  --accent-tertiary:#2b3544;
  --toastify-color-warning: #f1c40f;
  --toastify-text-color-dark: #111111;
}
.App{
  width: 100vw;
  height: 100vh;
  background-color: var(--body-bg-primary);
  position: relative;
  overflow: hidden;
}
.loading-body{
  position: absolute;
  inset: 0;
  z-index: 3;
  object-fit: cover;
}
.loading-body video{
  position: absolute;
  inset:0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 /* popups */
.forgot-password_popup,.popup{
  width: 25%;
  min-width: 345px;
  height: 400px;
  position: absolute;
  bottom: 0rem;
  left:50%;
  transform:translate3d(-50%,100%,0);
  background-color: var(--accent-primary);
  padding-top: 4.5rem;
  padding-inline: 2rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: transform .5s ease-in-out,opacity .5s ease-in-out,visibility .5s ease-in-out;
}
.forgot-password_popup .popup-pin,.popup .popup-pin{
  content: " ";
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 15px;
  background: var(--accent-primary);
  border-radius: 15px;

  outline-offset: 3px;
  animation:1s ease-in-out infinite alternate bounce;
  cursor: pointer;
  overflow:clip;

  
  &::before{
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    width: 95%;
    height: 80%;
    background-color: var(--body-bg-primary);
    border-radius: 15px;
    z-index: 1 !important;
  }
  &::after{
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    transform-origin: center;
    width: 600px;
    height:600px;
    background: conic-gradient(var(--accent-primary) 0% 20%,var(--accent-secondary) 20% 98%,var(--accent-primary) 98% 100%);
    z-index: -1 !important;
    animation: 5s linear infinite forwards spinpin;
  }
  /* border: 1px solid var(--font-color-secondary); */
}

@keyframes spinpin{
  0%{
    transform:translate3d(-50%,-50%,0) rotateZ(0);
  }
  100%{
    transform:translate3d(-50%,-50%,0) rotateZ(360deg);
  }
}
/* .close-popup{
  position: absolute;
  right: 1rem;
  top:.5rem;
  font-size: large;
  color: var(--font-color-primary);
  cursor: pointer;
} */
.forgot-password_popup.active,.popup.active{
  visibility: visible;
  opacity: 1;
  transform:translate3d(-50%,0,0);
}
.popup_header h2{
  color: var(--font-color-primary);
}

.reset-form,.request-form{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.reset-form .form-ctrl,.request-form .form-ctrl{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.request-form .form-ctrl .rw{
  display: flex;
  align-items: center;
  gap: .5rem;
  justify-content: flex-start;

  input{
    width: 20px;
    accent-color: var(--accent-secondary-alpha);
  }
}
.reset-form .form-ctrl label,.request-form .form-ctrl label{
  color: var(--font-color-secondary);
}


@keyframes bounce {
  0%{
    transform: translate3d(-50%,0,0);
  }
  60%{
    transform: translate3d(-50%,8px,0);
  }
  80%{
    transform: translate3d(-50%,0px,0);
  }
  100%{
    transform: translate3d(-50%,5px,0);

  }
}
/*end of popups */
.header{
  width: 95vw;
  height: 8vh;
  padding:0.2rem;
  top: 0;
  left: 50%;
  transform: translate3d(-50%,0,0);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--font-color-primary);
  border-bottom: 1px solid var(--font-color-secondary-alpha);
  z-index: 500;
}
.header-left{
  display: flex;
  align-items: center;
  gap: .5rem;
}
.header-right{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.header-right .notification{
  display: flex;
  align-items: center;
}
.header-right .user{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.header h2{
  font-size: clamp(0.8em,2.7vw,1.2em);
  font-family: nueuelight;
}

.notification{
  position: relative;

}
.notification.active::after{
  content: attr(aria-valuenow);
  position: absolute;
  top: -.5rem;
  left:-.5rem;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem;
  font-size: small;
  font-weight: bold;
}
.notification_list{
  position: absolute;
  width: fit-content;
  min-width: 300px;
  right: 100%;
  top: 100%;
  height: 400px;
  padding: .5rem;
  overflow: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  background-color: var(--body-bg-primary);
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: visibility .2s ease-in-out,opacity .2s ease-in-out;
}
.notification_list.active{
  visibility: visible;
  opacity: 1;
  
}
.notification_list:hover{
    scrollbar-color:var(--accent-secondary-alpha) transparent ;  
}
.notification_list ul{
  list-style: none;
  display: flex;
  flex-direction: column;
  gap:.2rem;
}
.notification_list ul li{
  padding: .2rem;
  display: flex;
  flex-direction: column;
  gap: .2rem;
  border-bottom: 1px solid var(--accent-secondary-alpha);
}
.notification_list ul li .notification_header{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mark_all_asread{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-block: .5rem;
  cursor: pointer;
}

.mark_as_read{
  cursor: pointer;
}
.notification_list ul li:last-child{
  border-bottom: none;
}

/* .unread{
  background-color: var(--accent-secondary-alpha);
} */
@media (max-width:563px) {
  .notification_list{
    right: -75%;
    transform: translateX(30%);
  }
}
.login-page{
  width: 100%;
  height:100%;
  display: flex;
  position: relative;

  .guide{
    padding:.5rem;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: var(--accent-secondary-alpha);
    cursor: pointer;
    animation: 2s linear infinite guide;
    
    
    &::before{
      position: absolute;
      border-radius: 50%;
      content: "";
      inset: 0;
      width: 100%;
      height: 100%;
      animation: 2s linear infinite guide .5s;
    }

    &::after{
      content:"Click Here For Video Tutorial";
      min-width:250px;
      position: absolute;
      z-index: 3;
      top: 100%;
      left: 100%;
      background-color: var(--accent-secondary-alpha);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: .2rem;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      animation: 5s linear forwards guideTitle;
    }
  }
  .video-guide{

    display: none;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 3;
    width: 80%;
    height: 80%;
    border: 2px solid var(--accent-secondary-alpha);
    border-radius: 12px;
    background-color: var(--accent-tertiary);
    
    &.active{
      display: block;
    }

    .close-guide{
      position: absolute;
      top: -2rem;
      left: 98%;
      background-color: var(--accent-primary);
      padding: .5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      animation: 2s linear infinite guide;
    }
    video{
      width: 100%;
      height: 100%;
      object-fit: contain;
      /* display: none; */
    }
  }
}

@keyframes guide {
  0%{
    outline: none;
  }
  20%{
    outline: solid;
    outline-color: white;
    outline-width:1px;
    outline-offset: .2rem;
  }
  45%{
    outline-offset: .6rem;

  }
  70%{
    outline-offset: .8rem;

  }

  100%{
    outline: none;
  }
}

@keyframes guideTitle{
  0%{
    visibility: visible;
    opacity: 1;
  }
  100%{
    visibility: hidden;
    opacity: 0;
  }
}
.login-left,.login-right{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.tab-container{
  width: 55%;
  margin-top: 4rem;
  min-width: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap:1.5rem;
}

.header-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-container h2{
  font-size: clamp(1.2em,2.7vw,1.5em);
  font-family: nueuelight;
  color: var(--font-color-primary);
}
.login-container,.signup-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:2rem;
  display: none;
  overflow: scroll;
}


.login-container::-webkit-scrollbar,.signup-container::-webkit-scrollbar,.signup-container{
  display: none;
}
.login-form{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: .5rem;
  color: var(--font-color-secondary);
}
.login-header{
  display: flex;
  align-items: center;
  color: var(--font-color-primary);
}
.login-header h1{
  font-size: clamp(1.2em,2.7vw,3em);
  font-family: nueueBold;
}
.login-form .form-ctrl,.adduser-form .form-ctrl{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.login-form .form-ctrl input,.reset-form .form-ctrl input,.request-form .form-ctrl input,.adduser-form .form-ctrl input,.request-form .form-ctrl select,.login-form select{
  height: 34px;
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: #f2f2f2;
  box-shadow: 0 0 5px -2px;
  text-indent: 1rem;
  font-size: 1em;
  position: relative;
  color: #111827;
}


.login-form .form-ctrl input:focus,.reset-form .form-ctrl input:focus,.request-form .form-ctrl input:focus,.request-form .form-ctrl select:focus{
  outline: none;
}
.forgot-signin{
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.forgot-signin p{
  text-decoration: underline;
  color: var(--font-color-secondary);
  cursor: pointer;
}
.forgot-signin .btn-login,td .btn-login,.loading-btn,.admin-dashboard .table-wrapper .card-header .btn-login{
  padding:10px 12px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  background-color: var(--accent-secondary);
  color: #f2f2f2;
}
.admin-dashboard .table-wrapper .card-header{
  display: flex;
  align-items:center ;
  justify-content: space-between;

}

.admin-dashboard .table-wrapper{
  height: 100%;
  overflow: scroll;
}
.admin-dashboard .table-wrapper::-webkit-scrollbar{
  display: none;
}
.admin-dashboard .table-wrapper .card-header .btn-login{
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:.5rem;
}

.signup{
  color: var(--font-color-secondary);
  width: 55%;
  min-width: 300px;
  padding: .5rem;
  display: flex;
  gap: .3rem;
}
.tab{
  color: var(--font-color-primary);
  cursor: pointer;
}


.sigup-btn-conatiner{
  justify-content: flex-end;
}


.login-right{
  background: url('./asset/Image/wine2.jpg');
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size:950px;
}

.active-signin{
  display: flex;
  animation: .3s ease-in forwards activesignin;
}
.active-signup{
  display: flex;
  animation: .3s ease-in forwards activesignup;
  gap: .5rem;
}

@keyframes activesignin {
  from{
    opacity: 0;
    transform: translate3d(-100%,0,0);
  }
  to{
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}
@keyframes activesignup {
  from{
    opacity: 0;
    transform: translate3d(100%,0,0);
  }
  to{
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}
@media (max-width:620px) {
  .login-right{
    display: none;
  }
  .login-left{
    width: 100%;
  }
}


/*dashboard */
.user-dashboard_page{
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.user-dashboard_page:-webkit-scrollbar{
  display: none;
}
.dashboard-main{
  height: 92vh;
  width: 95vw;
  margin-inline: auto;
  margin-top:8vh;
  padding-block: 1rem;
  color: var(--font-color-primary);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 1rem;
}
.dashboard-cards::-webkit-scrollbar,.dashboard-main::-webkit-scrollbar{
  display: none;
}
.dashboard-cards{
  width: 100%;
  min-height: fit-content;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-block: 1.5rem;
  /* overflow: scroll; */
}

.dashboard-cards>div{
  width: 20%;
  min-width: 300px;
  min-height: 180px;
  background-color: var(--accent-primary);
  border-radius: 12px;
  padding: 1rem;
  cursor: pointer;
}
.card-name{
  padding: 1.5rem;
  border-radius: 12px;
  background:linear-gradient(90deg,var(--accent-secondary-alpha),var(--accent-primary));
}
.dashboard-table{
  width: 100%;
  height: fit-content;
  padding-block: 1rem;
}


.popup form{
  overflow: scroll;
  padding-block-end:2rem;
}
.popup.active{
  bottom: 0;
}
.popup form::-webkit-scrollbar{
  display: none;
}

.form-ctrl span{
  color: rgb(236, 111, 111);
}
.form-ctrl option{
  color: var(--body-bg-primary);
}

/*admin dashboard*/

.dashboard-layout{
  width: 100%;
  height: 100%;
  display: flex;
}

.sidebar{
  position:relative;
  height: 100%;
  width: 8%;
  min-width: 120px;
  padding-block: 1.5rem;
  background-color: var(--accent-primary);
  border-top-right-radius:22px;
  border-bottom-right-radius:22px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap:2rem;
  transition:transform .5s ease-in;
  z-index: 1000 !important;
  
}
.sidebar-humburger{
  position: absolute;
  width: 40px;
  height: 40px;
  flex-direction: column;
  gap:.3rem;
  top:2rem;
  right: 1rem;
  display: none;
  cursor: pointer;
  justify-content: center;
}
.sidebar-humburger>div{
  width: 95%;
  height: 3px;
  background-color:var(--font-color-primary);
  border-radius: 2px;
  transition: transform .5s linear;
  }
  .sidebar-humburger>div:nth-child(1){
    transform: translate3d(0,0,0) scaleX(-.5);
    transform-origin: left;
  }
  .sidebar-humburger>div:nth-child(2){
    transform: translate3d(0,0,0) scaleX(0);
    transform-origin: right;
  }
  .sidebar-humburger>div:nth-child(3){
    transform: translate3d(0,0,0) scaleX(-.5);
    transform-origin: left;
  }
.sidebar-humburger>div:nth-child(1){
  width: 80%;
}
.sidebar-humburger>div:nth-child(3){
  width: 70%;
}

.sidebar.active .sidebar-humburger>div:nth-child(1).active{
  transform: translateX(25%) scaleX(1);

}
.sidebar.active .sidebar-humburger>div:nth-child(2).active{
  transform: translateX(5%) scale(1);
}
.sidebar.active .sidebar-humburger>div:nth-child(3).active{
  transform: translateX(40%);
}
.sidebar-main{
  width: 100%;
  color: var(--font-color-primary);
}
.sidebar-main ul{
  display: flex;
  flex-direction: column;
  gap:1rem;
  list-style: none;
  width: 100%;
  min-width: fit-content;
}
.sidebar-main ul a{
  width: 100%;
  text-decoration: none;
  padding: .1rem;
  border-radius: 8px;
  cursor: pointer;
  min-width: fit-content;
  text-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .3rem;
  font-size: small;
}

.sidebar .logo{
  align-self: center;
}
.sidebar-main ul a.active{
  background: linear-gradient(90deg,var(--accent-secondary-alpha),var(--accent-primary));
  /* background-color: red; */
}
.page-name{
  text-transform:capitalize;
}
.admindashboard-main{
  position:relative;
  width:100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
}
.admindashboard-main .header{
  width: 95%;
  align-items: center;
}
.user{
  position: relative;
  border-bottom: 1px solid var(--accent-secondary-alpha);
  cursor:pointer;
}

.logout{
  padding: 2px 4px;
  position: absolute;
  bottom:-2.2rem;
  left: 50%;
  width: 100%;
  text-align: center;
  background-color: var(--accent-tertiary);
  transform: translate3d(-50%,-50%,0) scaleY(0);
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  cursor: pointer;
}



 
.logout.active{
  animation: .5s ease-in-out forwards logouta;
}



 
@keyframes logouta {
  0%{
    opacity: 0;
  visibility: hidden;
    transform: translate3d(-50%,-50%,0) scaleY(0);
  }
  100%{
    transform: translate3d(-50%,-50%,0) scaleY(1);
    opacity: 1;
    visibility: visible;
  }
}
.admindashboard-main .header .header-left{
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;

}
.header-humburger{
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: .3rem;
  display: none;
  cursor: pointer;
}
.header-humburger>div{
  width: 95%;
  height: 3px;
  background-color: var(--font-color-primary);
  border-radius: 2px;
}
.header-humburger>div:nth-child(1){
  width: 80%;
}
.header-humburger>div:nth-child(3){
  width: 70%;
}
.admin_main-container{
  width: 95%;
  margin-inline: auto;
  margin-top: 3vh;
  height: 89vh;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}
.page-name{
  min-height: fit-content;
  padding: 1rem;
}
.admin-outlets{
  height: 100%;
}

.admin-dashboard{
  padding-top: 1rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.admin-dashboard::-webkit-scrollbar{
  display: none;
}
.admin-dashboard .cards{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  height: 90%;
  overflow: scroll;
  padding: 1rem;
}
.admin-dashboard .cards::-webkit-scrollbar{
  display: none;
}
.admin-dashboard .cards .card{
  flex-grow: 1;
  min-width: 300px;
  /* max-width: 300px; */
  min-height: 150px;
  max-height: 200px;
  background-color: var(--accent-primary);
  border-radius: 12px;
  padding: 1rem;
}
.admin-dashboard .cards .card .number{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
  font-size: clamp(1.5em,2.7vw,3em);
}
.admin-dashboard .cards .card:nth-child(1) .number h1{
  color: var(--accent-secondary);
}
.admin-dashboard .cards .card:nth-child(2) .number h1{
  color: aqua;
}
.admin-dashboard .cards .card-chart{
  flex-grow: 1;
  min-width: 300px;
  min-height: 250px;
  max-height: 360px;
  background-color: var(--accent-primary);
  border-radius: 12px;
  padding: 1rem;
}
.admin-dashboard .cards .card .card-header{
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.card-header form{
  width: 40%;
}
.search-input{
  width: 100%;
  border: 1px solid var(--body-bg-primary);
  background-color: var(--accent-primary);
  padding: .4rem;
  color: white;
}
.search-input:focus{
  outline: none;
}
@media (max-width:636px) {
  .card-header form{
    width: 30px;
    height: 30px;
    position: relative;
  }
  .search-input{
    height: 100%;
    border-radius: 50%;
  }
  .search-input:focus{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 200px;
    border-radius: 0;
    z-index: 5;
  }
  
}

.admin-dashboard .cards .card-chart .charts{
  /* overflow: scroll; */
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111827 !important;
  align-self: center;
  justify-self: center;
}
.admin-dashboard .cards .card-chart .charts::-webkit-scrollbar{
  display: none;
}

svg tspan{
  fill: var(--font-color-primary);
}
svg .css-1qkz8p7{
  fill: #f2f2f2;
}

.pie svg .MuiChartsLegend-root{
  transform: translate3d(60px,0,0);
}
.pie svg .css-1mhcdve-MuiPieArc-root{
  stroke: none;
  stroke-width: 0;
}
 @media (max-width:815px) {
  .admin-dashboard .table-wrapper .card-header .btn-login{
    padding: 4px 8px;
  }
  .admin-dashboard .table-wrapper .card-header .btn-login span{
    display: none;
  }

  
  .sidebar{
    position: absolute;
    top: 0;
    z-index: 3;
    width: 100%;
    transform: translate3d(-100%,0,0);
    
  }
  .sidebar.active{
    transform: translate3d(0,0,0);
  }
  .admindashboard-main{
    width: 100%;
  }
  .admindashboard-main .cards{
    justify-content: center;
  }
  .header-humburger{
    display: flex;
  }
  .sidebar-humburger{
    display: flex;
    transition: all 1s linear;
  } 
}

/* Table Css */
/* .table-wrapper{
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.table-wrapper table thead{
  display: none;
}
.table-wrapper table tr td{
  display: block;
  width: fit-content;
  text-wrap: nowrap;
}
.table-wrapper table tr td::before{
  content: attr(data-cell) ":";
  padding-inline: 1rem;
  color: var(--font-color-primary);
} 
} */

/* dashboard-table */

.card-table{
  flex-grow: 1;
  min-width: 300px;
  min-height: fit-content;
  background-color: var(--accent-primary);
  border-radius: 12px;
  padding: 1rem;
}

.card-table table{
  width: 100%;
  margin-block: 1rem;
  border-collapse: collapse;
}
.card-table .table{
  overflow: scroll;
}
.card-table .table::-webkit-scrollbar{
  display: none;
}
.card-table table thead{
  background-color: var(--accent-tertiary);

}
.card-table table td{
  padding: 1rem;
  text-align: left;
}
.card-table thead th{
  padding-inline: 1rem;
  padding-block: .5rem;
  text-align: left;
}
.card-table table tr{
  border-bottom:.5px solid #f2f2f225;

}
.card-table table tr td{
  color: var(--font-color-secondary);
}
.card-table table tr:last-child{
  border-bottom: none;
}

.pagination-main{
  width: 100%;
  display: flex;
}
.pagination-main ul{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: .5rem;
  list-style: none;

}
.pagination-main ul li{
  padding: 5px 10px;
  text-decoration: none;
}
.pagination-main ul li a{
  text-decoration: none;
}
.pagination-main ul li {
  background-color: var(--accent-secondary);
}
.pagination-main ul li.active {
  background-color: var(--accent-secondary-alpha);
}
.pagination-main ul li:nth-child(1),.pagination-main ul li:last-child{
  background-color: var(--accent-tertiary);
}




/* Table Css */

.table-wrapper{
  padding-inline: 1rem;
  width: 100%;
  height: fit-content;
}
.table-wrapper table{
  border-collapse: collapse;
  width: 100%;
}
.table-wrapper table td{
  padding:1rem;
  text-align: left;

}
.table-wrapper table th{
  padding-inline: 1rem;
  padding-block: .5rem;
  text-align: left;
}

.table-wrapper table tr{
  background-color: var(--accent-primary-alpha);
}
.table-wrapper table tr:nth-of-type(2n){
  background-color: var(--accent-primary);
  
}
.table-wrapper table tr td{
  color: var(--font-color-secondary);
}
.table-wrapper table thead{
  background-color: var(--accent-tertiary);
  padding-block: 5.5rem;
}

/*Manage*/

.manage-tabs{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.manage-tabs span{
  border: 1px solid var(--accent-secondary-alpha);
  padding: 5px 10px;
  border-radius: 24px;
  cursor: pointer;
  transition: .2s;
}
.manage-tab.active{
  background-color: var(--accent-secondary-alpha);
}
.adduser-form{
  width: 40%;
  min-width:320px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  margin-inline: auto;
}


/*tickets*/

.tickets-main{
 display: flex;
 height: 80vh;
 gap: .1rem;
 position:relative;
 overflow: hidden;
}

.tickets-main>div{
 flex:1;
 padding: 1rem;
 background-color: var(--accent-primary);
}
.tickets-main>div:nth-child(1){
  border-top-left-radius: 15px;
}
.tickets-main .tickets-preview{
  position: relative;
  min-width: 55%;
  overflow: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
.tickets-main .tickets-preview:hover {
  scrollbar-color:var(--accent-secondary-alpha) transparent ;
}
.tickets-preview .ticket-status{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tickets-preview .expand-ticketlist,.tickets-list .expand-ticketlist{
  display: none;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  filter: grayscale(1);
}
.ticket-history .state{
  display: flex;
  gap: 1rem;
  position: relative;
  padding-left: 2rem;
}
.ticket-history .state:nth-child(1)::after{
  height: 100%;
  top: 1rem;
  animation: timeline 1s linear var(--delay) forwards;
}

.ticket-history .state::before{
  content: "";
  width: 8px;
  height: 8px;
  background-color: yellow;
  position: absolute;
  left: .5rem;
  top: .7rem;
  border: 2px solid green;
  border-radius: 50%;
  z-index: 1;
}
.ticket-history .state::after{
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: green;
  left: .85rem;
  top: 1rem;
  transform-origin: top;
  transform: scaleY(0);
  animation: 1s linear var(--delay) forwards timeline;
}
.ticket-history .state:last-child::after{
  width: 1px;
  height: 0px;
  transform: scaleY(0);
  animation: timeline 1s linear var(--delay) forwards ;
}

.refresh{
  animation:1s linear infinite refresh;
}



@keyframes refresh {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
  
}

@keyframes timeline {
  0%{
    transform: scaleY(0);
  }
  100%{
    transform: scaleY(1);
  }
}
.ticket-history .state .state-date{
  padding-block: .5rem;
}
.ticket-history .state .state-detail{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-block: .5rem;
}
.tickets-list{
  display: flex;
  width: 22.5%;
  flex-direction: column;
  gap:1rem;
  padding:1rem 0 !important;
}
.tickets-list .sort-refresh{
  display: flex;
  align-items: center;
  justify-content:space-between;
  padding-inline:1rem;
}

.tickets-list p{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tickets-list ul{
  display: flex;
  height: 100%;
  flex-direction: column;
  list-style: none;
  justify-content: flex-start;
  overflow-y: scroll;
  cursor: pointer;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
.tickets-list ul:hover {
  scrollbar-color:var(--accent-secondary-alpha) transparent ;
}


.tickets-list ul li{
  min-height: 80px;
  display: flex;
  flex-direction: column;
  padding: .2rem 1.2rem;
  overflow: hidden;
}
.tickets-list ul li .list-requestor-name{
  flex: 10%;
  font-weight: 600;
}
.tickets-list ul li>p{
  flex: 80%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-block:0;
}
.tickets-list ul .ticketid-status{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tickets-list ul li.active{
  background-color:var(--accent-secondary-alpha);
}

.tickets-preview,.ticket-highlight{
  display: flex;
  flex-direction: column;
  gap:1rem;
}
.ticket-description,.ticket-note,.ticket-solution,.ticket-highligh-status,.ticket-highlight-requestor{
  display: flex;
  flex-direction: column;
  gap:.3rem;
}
.ticket-description>p , .ticket-note>p,.highlight-lable,.ticket-highligh-status p{
  opacity: .9;
  font-weight: lighter;
  font-size: smaller;
}
.ticket-solution .ticket-form{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ticket-solution .ticket-form textarea{
  background-color: transparent;
  color: white !important;
  padding: .5rem;
  text-indent: 1rem;
  border-radius: 8px;
  font-size:medium;
  height: 100px;
  resize: none;
}
.ticket-solution .ticket-form textarea::-webkit-scrollbar{
  display: none;
}
.ticket-solution .ticket-form textarea:focus{
  outline: none;
}
.ticket-solution button{
  width: fit-content;
  padding:10px 12px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  background-color: var(--accent-secondary);
  color: #f2f2f2;
}
.tickets-main>div:nth-child(3){
  padding: 0;
  display: flex;
  gap: 0;
}
.flx-row{
  display: flex;
  align-items: center;
  gap:.5rem;
  border-bottom: 1px solid var(--body-bg-primary);
  padding: 1rem 1rem;
}
.flx-row>div:nth-child(1){
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ticket-highlight-datail{
  padding: 0rem;
  display: flex;
  flex-direction: column;
  gap:.3rem;
}
.ticket-highlight-datail .detail-header{
  background-color: var(--body-bg-primary);
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  border: none;

}
.ticket-highlight-datail .detail-header>ul{
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  position: absolute;
  left: 0;
  top:100%;
  transform-origin: top;
  opacity: 0;
  visibility: hidden;
  transition: all .1s;
  z-index: 3;
  border-bottom:1px solid var(--body-bg-primary);
}
.ticket-highlight-datail .detail-header>ul.active{
  opacity: 1;
  visibility: visible;
} 

.ticket-highlight-datail .detail-header>ul li{
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.ticket-highlight-datail .detail-header>ul li>div{
  flex: 1;
  text-align: left;
}
@keyframes collapsdetail {
  0%{
    opacity: 0;
    visibility: hidden;
  }
  100%{
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width:710px){
  .tickets-list{
    position: absolute;
    inset: 0;
    z-index: 2;
    width:fit-content !important;
    transform: translateX(-100%);
    transition: .5s transform ease-in-out;
    border-right: 3px solid var(--body-bg-primary);
  }
  .tickets-list.expand{
    transform: translateX(0);
    
  }
  .tickets-preview .expand-ticketlist,.tickets-list .expand-ticketlist{
    display: block;
  }
}

.opt-dots{
  display: flex;
  align-items: center;
  justify-content: center;
}
.opt-dots button{
  width: fit-content;
  padding:5px 10px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  background-color: var(--accent-secondary);
  color: #f2f2f2;
}
.filter-div{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}
.filter-div form{
  display: flex;
  gap:.5rem;
  padding: .2rem;
}
.filter-div form select{
  color: white;
  background-color: #2b3544;
  border: none;
}

.filter-div form select:active{
  outline: none;
}
.Toastify__toast-body div{
  color: black !important;
}
.Toastify__toast .Toastify__toast-theme--light .Toastify__toast--warning{
  background-color: var(--accent-tertiary) !important;
}

.loading-btn{
  padding: 4px 18px;
}
.spinner{
  width: 20px;
  height: 20px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: conic-gradient(white 0% 45%,var(--accent-secondary) 45% 100%);
  border-radius: 50%;
  animation: 2s linear infinite load;
}

.spinner-top{
  width: 80%;
  height: 80%;
  background-color:var(--accent-secondary);
  border-radius: 50%;
}

@keyframes load {
  0%,50%{
    transform: rotate(0deg);
  background: conic-gradient(white 0% 45%,var(--accent-secondary) 45% 100%);

  }
  70%{
    transform: rotate(270deg);
  background: conic-gradient(white 0% 85%,var(--accent-secondary) 85% 100%);

  }
  70%{
    transform: rotate(360deg);
  background: conic-gradient(white 0% 100%);
  }
  100%{
    background: conic-gradient(white 0% 45%,var(--accent-secondary) 45% 100%);
  }
}

.report-page{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.report-page .filters{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
  background-color: var(--accent-tertiary);
  padding: 1.5rem;
  border-radius: 5px;
  overflow-x: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
.report-page .filters:hover{
  scrollbar-color:var(--accent-secondary-alpha) transparent ;
}
.report-page .filters .form-ctrl{
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.report-page .filters .form-ctrl input,.report-page .filters .form-ctrl select{
  padding: .4rem;
  border-radius: 4px;
  border: 1px solid var(--body-bg-primary);
  text-align: center;
  background-color: transparent;
  color: white;
}
.report-page .filters .form-ctrl select option{
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 105px;
  text-align: left;
  
}
.report-page .filters .form-ctrl input:focus,.report-page .filters .form-ctrl select:focus{
  outline-color: var(--accent-secondary) !important;

}
.report-page .table-wrapper{
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
.report-page .table-wrapper:hover{
  scrollbar-color:var(--accent-secondary-alpha) transparent ;
}

.statistics{
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.statistics-container{
  display: flex;
  gap: 1rem;
  overflow: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  justify-content: space-between;
}
.statistics-container:hover{
  scrollbar-color:var(--accent-secondary-alpha) transparent ;
}
.statistics-container>div{
  background-color: var(--body-bg-primary);
}
.statistics-clmns{
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding: 1rem;
  flex: 1;
  border-radius: 8px;
}
.statistics-rws{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: #f2f2f2de;
  font-size: small;
}
.statistics-rws .value{
  color: var(--accent-secondary);
}
.statistics-rws:first-child{
  border-bottom: 1px solid var(--accent-secondary-alpha);
  /* font-weight: 600; */
  font-size:larger;
}



.pie .css-1u0lry5-MuiChartsLegend-root{
  outline: solid 3px blue;
    outline-offset: 5px;
  background-color: red !important;
  display: none;
}

.message{
  width:300px;
  height: fit-content;
  padding: 1rem;
  background-color: var(--accent-tertiary);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a{
    padding: .5rem;
    background-color: var(--accent-primary-alpha);
    font-weight: 500;
    text-decoration: none;
  }
}